import React, { useState, useRef } from "react";
import { Button, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const UploadDocumentsfromExperts = ({
  handleUploadExpertDocs,
  fileUploaded,
}) => {
  const hiddenFileInput = useRef(null);

  // Trigger the file input click programmatically
  const handleButtonClick = () => {
    hiddenFileInput.current?.click();
  };

  // Handle file selection
  const handleChange = (event) => {
    const fileUploaded = event.target.files?.[0];
    if (fileUploaded) {
      // Check if file size exceeds 10MB
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (fileUploaded.size > maxSizeInBytes) {
        alert("File size exceeds 10MB. Please select a smaller file.");
        return;
      }

      // Call the handleUploadExpertDocs function with the uploaded file
      handleUploadExpertDocs(fileUploaded, "FILE");
      event.target.value = null; // Reset input value
    }
  };

  return (
    <>
      <input
        type="file"
        accept="application/pdf"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
      <Tooltip
        placement="left"
        title="Upload any document here to share it with the patient and add it to their clinical history. This may update the patient's clinical notes."
      >
        <Button
          variant="primary"
          className="PrimaryCTAExp"
          onClick={handleButtonClick}
          disabled={fileUploaded !== "idle"}
        >
          {fileUploaded === "idle" ? (
            "Upload Documents"
          ) : (
            <CircularProgress
              style={{
                width: "25px",
                height: "25px",
                color: "var(--primary-base-color)",
              }}
            />
          )}
        </Button>
      </Tooltip>
    </>
  );
};

export default UploadDocumentsfromExperts;
