import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import ClientDetailsTableRow from "./tables/ClientDetailsTableRow";
import AddAppointmentModal from "./modals/AddAppointmentModal";
import ClientDetailsView from "./component/clients/ClientDetailsView";
import ScreenerDetailsView from "./component/clients/ScreenerDetailsView";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Checkbox from "@mui/material/Checkbox";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

//Date Picker

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//Text Field
import TextField from "@mui/material/TextField";

import moment from "moment";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

//Switch
import Switch from "@mui/material/Switch";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import jwtDecode from "jwt-decode";
import { useAuth } from "../../auth";

//import { useHistory } from "react-router";

//Icons
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddIcon from "@mui/icons-material/Add";

import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation, Link } from "react-router-dom";

//Firebase
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebaseConfig";

//Loader spinner
import CircularProgress from "@mui/material/CircularProgress";
import {
  Breadcrumbs,
  FormControlLabel,
  FormGroup,
  Pagination,
  Typography,
} from "@mui/material";

//APIS
import { postAddSessionData } from "./APIS/clientAPIS";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { getToken } from "./APIS/clientAPIS";
import { NavigateNext } from "@mui/icons-material";
import { NoDataSvg } from "./helpers/Svg";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

dayjs.extend(localizedFormat);

const ExpertClientDB = () => {
  const { currentUser, userType, logout } = useAuth();

  const [showClientDetails, setShowClientDetails] = useState(false);
  const [selectedScreener, setSelectedScreener] = useState(null);
  const [client, setClient] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientList, setClientList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All Patients");
  const [clientDetails, setClientDetails] = useState({});
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [slotsList, setSlotsList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [clientData, setClientData] = useState({
    slotID: 0,
    clientID: 0,
    clientName: "",
    clientImage: "",
    clientType: "",
    appointmentID: 0,
    appointmentDate: "",
    appointmentTime: "",
  });

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openSessionNotesModal, setOpenSessionNotesModal] = useState(false);
  const [openSessionNotesTableModal, setOpenSessionNotesTableModal] =
    useState(false);
  const [note, setNote] = useState("");
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [prescriptionName, setPrescriptionName] = useState("");
  const [prescriptionNote, setPrescriptionNote] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");
  const [sessionNotesStep, setSessionNotesStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelConfirmationModal, setOpenCancelConfirmationModal] =
    useState(false);
  const [bearerToken, setBearerToken] = useState(
    sessionStorage.getItem("token")
  );
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const [sessionNotesData, setSessionNotesData] = useState({
    professional_id: currentUser?.UserId,
    form_id: "",
    appointment_date: "",
    client_mobile: "",
    client_dob: "",
    client_address: "",
    client_state_id: "",
    client_city: "",
    postal_code: "",
    country_id: "",
    uwc_client_id: clientData?.clientID,
    proceed: false,
  });

  //console.log("sessionNotesData", sessionNotesData)
  const [sessionNotesForm, setSessionNotesForm] = useState([]);
  const [sessionNotesTableForm, setSessionNotesTableForm] = useState([]);
  const [sessionNotesFormValue, setSessionNotesFormValues] = useState({});

  //Loaders States
  const [isFileLoading, setIsFileLoading] = useState(null);
  const [isCancelled, setIsCancelled] = useState(null);
  const [isPrescriptionAdded, setIsPrescriptionAdded] = useState(null);
  const [isRescheduled, setIsRescheduled] = useState(null);
  const [isNoteAdded, setIsNoteAdded] = useState(null);

  const [statesList, setStatesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [formsList, setFormsList] = useState([]);

  // Initialize state for text and dropdown responses
  const [textResponses, setTextResponses] = useState(
    new Array(sessionNotesForm?.length).fill([])
  );
  const [booleanResponses, setBooleanResponses] = useState(
    new Array(sessionNotesForm?.length).fill("")
  );
  const [selectionResponses, setSelectionResponses] = useState(
    new Array(sessionNotesForm?.length).fill([])
  );
  const [multiselectionResponses, setMultiselectionResponses] = useState(
    new Array(sessionNotesForm?.length).fill([])
  );
  const [dropdownResponses, setDropdownResponses] = useState(
    new Array(sessionNotesForm?.length).fill("")
  );

  const [multiQuestionRows, setMultiQuestionRows] = useState([]);

  const [textFieldsData, setTextFieldsData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  let userID;

  const fetchCountries = async () => {
    try {
      const response = await fetch(
        "https://us.uwc.world/new-uwc/country?all=true",
        {
          method: "GET",
          headers,
        }
      );

      const postResponse = await response.json();
      setCountriesList(postResponse.data.countries);
      // Handle the response data
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const fetchForms = async () => {
    try {
      const response = await fetch(
        "https://us.uwc.world/new-uwc/session-notes/get-forms",
        {
          method: "GET",
          headers,
        }
      );

      const postResponse = await response.json();
      //console.log("postResponse forms", postResponse)
      setFormsList(postResponse.data);
      // Handle the response data
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };
  //  console.log("countriesList", countriesList);
  //Fetch States
  const fetchStates = async () => {
    //const countriesList = await fetchCountries();
    if (countriesList?.length > 0) {
      try {
        //console.log("currentUser?.Country", currentUser?.Country)
        let countryItem = countriesList?.filter(
          (item) => item.id === sessionNotesData.country_id
        );
        let countryCode = countryItem[0]?.code;

        // console.log("countryCode", countryCode)

        const response = await fetch(
          `https://us.uwc.world/new-uwc/state/${countryCode}`,
          {
            method: "GET",
            headers,
          }
        );

        const postResponse = await response.json();
        const statesArray = await postResponse.data?.states;

        // console.log("statesArray", statesArray);
        setStatesList(statesArray);
        // Handle the response data
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    }
  };

  //APIS or functions that need to be called only once
  useEffect(() => {
    fetchCountries();
    fetchForms();
  }, []);

  useEffect(() => {
    fetchStates();
  }, [openSessionNotesModal]);

  // Check if user is logged in
  // useEffect(() => {
  //   const isLoggedIn = isUserLoggedIn();

  //   if (!isLoggedIn) {
  //     // Redirect to "/login"
  //     //navigate("/login");
  //     window.location.href = `https://www${process.env.REACT_APP_CLIENT_DOMAIN}/`
  //   } else {
  //     userID = JSON.parse(localStorage.getItem("profile")).UserId;
  //   }
  // }, [location, navigate]);

  //Use effect to open Client Details
  useEffect(() => {
    if (
      window.location.href.split("?")[1] !== undefined &&
      window.location.href.split("?")[1].includes("id=")
    ) {
      const searchParams = new URLSearchParams(location.search);
      const clientName = searchParams.get("patient");
      let formattedClientName = clientName
        ? decodeURIComponent(clientName).replace(/%20/g, " ")
        : "";

      window.location.href.split("?")[1].includes("id=");
      const clientIDFromURL = searchParams.get("id");

      setClientID(clientIDFromURL);
      if (
        formattedClientName == "" ||
        formattedClientName == null ||
        formattedClientName == undefined
      ) {
        let filteredClientDetails = clientList.filter(
          (item) => +item.ID == +clientIDFromURL
        );
        //console.log("filteredClientDetails",filteredClientDetails[0].Name)
        if (filteredClientDetails?.length > 0) {
          //console.log("HERW")
          formattedClientName = filteredClientDetails[0].Name;
        }
      }
      setClient(formattedClientName);

      setShowClientDetails(true);
      let [clientItem] = clientList?.filter(
        (item) => item.ID === clientIDFromURL
      );

      setClientDetails({
        id: clientItem?.ID,
        name: clientItem?.Name,
        image: clientItem?.ProfileImage,
        country: clientItem?.Country,
        MobileNumber: clientItem?.MobileNo,
        Address: clientItem?.Address,
        City: clientItem?.City,
      });

      //console.log("clientItem", clientItem);
    } else {
      setShowClientDetails(false);
      setSelectedScreener(null);
    }
  }, [window.location.search, clientList]);

  const postSlotsData = async () => {
    const payload = {
      user_id: userID,
      date: moment(clientData?.appointmentDate, "DD/MM/YYYY").format(
        "DD-MM-YYYY"
      ),
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/slots`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();
      const { slots } = await postResponse.data;

      setSlotsList(slots);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Fetch Clients
  useEffect(() => {
    const payload = {
      expert_id: currentUser?.UserId,
      page: page,
    };

    const postClientsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/clients`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { clients, total_pages } = await postResponse.data;
        //const token = await postResponse.data;
        //const decodedToken = jwtDecode(token);

        setClientList(clients);
        setTotalPage(total_pages);
        setIsLoading(false);
        // console.log("clients", clients)
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientsData();
  }, [page]);

  const handleRowClick = (
    name,
    id,
    image,
    country,
    notes,
    screener,
    sessions,
    type,
    worksheets
  ) => {
    const queryParam = `patient=${name}&id=${id}`;
    const currentPath = window.location.pathname;
    const updatedPath = `${currentPath}?${queryParam}`;

    setClientDetails({
      name,
      id,
      image,
      country,
      notes,
      screener,
      sessions,
      type,
      worksheets,
    });

    setClient(name);
    setClientID(id);

    //console.log("updatedPath", updatedPath)
    navigate(updatedPath);

    setShowClientDetails(true);
  };

  const handleViewScreener = (title, id, type) => {
    //console.log("TOP", id)
    setSelectedScreener({ title, id, type });
  };

  const handletabSelect = (event) => {
    setSelectedTab(event.target.innerText);
  };

  //console.log("clientList", clientList);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  ///Reschedule Region

  //Reschedule
  const handleReschedule = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    clientID
  ) => {
    // Perform actions to open the reschedule dialog
    // You can access the appointmentID, appointmentDate, and slotID here

    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      clientID,
    }));

    //console.log("appointmentDate", appointmentDate)
    //postSlotsData();

    handleRescheduleOpen();
    //handleRescheduleOpen();
  };

  const handleRescheduleOpen = () => {
    setOpenRescheduleModal(true);
  };

  const handleRescheduleClose = () => {
    setOpenRescheduleModal(false);
  };

  const onToDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setClientData((prevClientData) => ({
      ...prevClientData,
      appointmentDate: formattedDate,
    }));
  };

  const handleRescheduleAppointment = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries

    const payload = {
      AppointmentId: clientData?.appointmentID,
      ExpertId: currentUser?.UserId,
      AppointmentDates: clientData?.appointmentDate,
      App_StartTime: clientData?.appointmentTime,
      App_EndTime: dayjs(clientData?.appointmentTime, "hh:mm A")
        .add(60, "minutes")
        .format("hh:mm A"),
      CallMode: clientData?.callStatus,
      Status: clientData?.callingMode,
    };

    let sessionBearerToken = sessionStorage.getItem("token");

    const rescheduleHeaders = {
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
      DeviceId:
        "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
      DeviceType: "ios",
      Lat: 0,
      Long: 0,
      Authorization: `Bearer ${sessionBearerToken}`,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/reschedule`,
        {
          method: "POST",
          headers: rescheduleHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const reponseCode = await postResponse.ResponseCode;

      if (reponseCode == 200) {
        setSnackbarMessage("Resheduled successfully!");
        setOpenSnackbar(true);
        handleRescheduleClose();
      } else if (reponseCode == 401 && retryCount < MAX_RETRY) {
        const newToken = await getToken(currentUser?.Emailid);
        setBearerToken(newToken);
        setTimeout(() => handleRescheduleAppointment(retryCount + 1), 0);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Slots Section
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSlotChange = (slot) => {
    //console.log("SLOT", slot);
    setClientData((prevClientData) => ({
      ...prevClientData,
      slotID: slot.id,
    }));
  };

  ///Reschedule Region End

  ///Session Notes Region

  const handleSessionNotes = (
    clientName,
    clientImage,
    clientType,
    clientID,
    country,
    clientMobile,
    clientAddress,
    clientState,
    clientCity,
    clientPIN,
    clientDOB
  ) => {
    const currCountry = countriesList.filter((item) => item.name === country);

    let clientStateID = "";
    if (
      clientState !== false &&
      clientState !== undefined &&
      clientState !== undefined &&
      clientState !== ""
    ) {
      clientState = statesList.filter((item) => item.name == clientState)[0].id;
    }
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      clientID,
    }));
    setSessionNotesData((prevData) => ({
      ...prevData,
      country_id: currCountry[0].id,
      uwc_client_id: +clientID,
      client_mobile: clientMobile !== false ? clientMobile : "",
      client_address: clientAddress !== false ? clientAddress : "",
      client_city: clientCity !== false ? clientCity : "",
      postal_code: clientPIN !== false ? clientPIN : "",
      client_dob: clientDOB !== false ? clientDOB : "",
      client_state_id: clientStateID,
    }));

    handleSessionNotesOpen();
  };

  const handleSessionNotesOpen = () => {
    setOpenSessionNotesModal(true);
  };

  const handleSessionNotesClose = () => {
    setOpenSessionNotesModal(false);
    setSessionNotesData({
      professional_id: currentUser?.UserId,
      form_id: "",
      appointment_date: dayjs().format("YYYY-MM-DD"),
      client_mobile: "",
      client_dob: dayjs().format("YYYY-MM-DD"),
      client_address: "",
      client_state_id: "",
      client_city: "",
      postal_code: "",
      country_id: "",
      uwc_client_id: clientData?.clientID,
      proceed: false,
    });
  };

  ///Session Notes Region End

  ///Notes Region

  const handleNotes = (clientName, clientImage, clientType, clientID) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      clientID,
    }));
    handleNotesOpen();
  };

  const handleNotesOpen = () => {
    setOpenNotesModal(true);
  };

  const handleNotesClose = () => {
    setOpenNotesModal(false);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  const handlePostNote = async () => {
    setIsNoteAdded(false);
    const payload = {
      jsonrpc: "2.0",
      params: {
        name: note,
        content: note,
        creator_id: currentUser?.UserId,
        assign_id: clientData?.clientID,
        is_private: "True",
      },
    };

    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/uwc_notes/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (message == 200) {
        setNote("");
        setOpenNotesModal(false);
        setSnackbarMessage("Note sent successfully!");
        setOpenSnackbar(true);
        setIsNoteAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  ///Notes Region End

  ///Prescription Region

  const handlePrescription = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
    }));

    handlePrescriptionOpen();
  };

  const handlePrescriptionOpen = () => {
    setOpenPrescriptionModal(true);
  };

  const handlePrescriptionClose = () => {
    setOpenPrescriptionModal(false);
  };

  const handlePrescriptionName = (e) => {
    setPrescriptionName(e.target.value);
  };

  const handlePrescriptionNote = (e) => {
    setPrescriptionNote(e.target.value);
  };

  const handlePostPrescription = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries

    setIsPrescriptionAdded(false);
    const payload = {
      AppointmentId: +clientData?.appointmentID,
      Title: prescriptionName,
      Note: prescriptionNote,
      DocsFile: selectedFiles,
    };

    let sessionBearerToken = sessionStorage.getItem("token");

    const prescriptionHeaders = {
      "Content-Type": "application/json",
      deviceid: "123",
      devicetype: "web",
      lat: "1",
      long: "1",
      Authorization: `Bearer ${sessionBearerToken}`,
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/appointment/saveAppointment_Documents_Files`,
        {
          method: "POST",
          headers: prescriptionHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (message == 200) {
        setPrescriptionName("");
        setPrescriptionNote("");
        setSelectedFiles("");
        handlePrescriptionClose();
        setSnackbarMessage("Prescription sent successfully!");
        setOpenSnackbar(true);
        setIsPrescriptionAdded(true);
      } else if (message == 401 && retryCount < MAX_RETRY) {
        const newToken = await getToken(currentUser?.Emailid);
        setBearerToken(newToken);
        setTimeout(() => handlePostPrescription(retryCount + 1), 0);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Upload
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    //console.log("file", file);
    setIsFileLoading(true);
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg"
    ) {
      let dirName = moment().format("MMMM_YYYY");
      //console.log("dirName", dirName);
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          // setPercent(percent);
        },
        (err) => enqueueSnackbar(err, { variant: "error" }),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            // console.log("file path url ", url);
            //setLoading(false);
            setSelectedFiles(url);
            setIsFileLoading(false);
          });
        }
      );
    } else {
      console.error("Incorrect  file format.");
    }

    //setSelectedFiles(files);
  };

  ///Prescription Region End

  ///Cancel Region

  const handleCancelConfirmationOpen = () => {
    setOpenCancelConfirmationModal(true);
  };

  const handleCancelConfirmationClose = () => {
    setOpenCancelConfirmationModal(false);
  };

  const handleCancelAppointment = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
    }));

    handleCancelConfirmationOpen();
  };

  const handleCancel = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries

    setIsCancelled(false);
    const payload = {
      AppointmentId: clientData?.appointmentID,
    };

    let sessionBearerToken = sessionStorage.getItem("token");

    const cancelHeaders = {
      //"X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
      DeviceId:
        "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
      DeviceType: "ios",
      Lat: 0,
      Long: 0,
      Authorization: `Bearer ${sessionBearerToken}`,
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/appointment/CancelAppointment`,
        {
          method: "POST",
          headers: cancelHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (message == 200) {
        handleCancelConfirmationClose();
        setSnackbarMessage("Cancelled successfully!");
        setOpenSnackbar(true);
        setIsCancelled(true);
      } else if (message == 401 && retryCount < MAX_RETRY) {
        const newToken = await getToken(currentUser?.Emailid);
        setBearerToken(newToken);
        setTimeout(() => handleCancel(retryCount + 1), 0);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  ///Cancel Region End

  ///Add Appointment Region

  ///Add Appointment Region End

  const filteredClients = clientList.filter((item) => {
    if (selectedTab === "All Patients") {
      return true;
    } else if (selectedTab === "New Patients") {
      return item.client_type == "New Client";
    } else if (selectedTab === "Follow-up") {
      return item.client_type == "Follow-up";
    }
  });

  //console.log("clientList", clientList);

  ///Session Notes Region Start
  const onSessionNoteAppiontmentDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSessionNotesData((prevData) => ({
      ...prevData,
      appointment_date: formattedDate,
    }));
  };

  const onSessionNoteClientDOBDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSessionNotesData((prevData) => ({
      ...prevData,
      client_dob: formattedDate,
    }));
  };

  const handleProceed = async () => {
    //setSessionNotesStep(sessionNotesStep + 1);
    const newClientMobile = `+91 ${sessionNotesData.client_mobile}`;

    let payload = sessionNotesData;

    //payload.client_mobile = newClientMobile;

    //Send Data to API and await response
    //console.log("payload", payload);

    const resp = await postAddSessionData(JSON.stringify(payload), 1);
    setSessionNotesForm(resp.questions);
    setSessionNotesFormValues((prevData) => ({
      ...prevData,
      session_note_id: resp.session_note_id,
      responses: [],
    }));

    resp?.questions?.forEach((item) => {
      if (item.type == "multiquestion") {
        setSessionNotesTableForm(item.sub_questions);
      }
    });
    setSessionNotesStep(2);
  };

  const updateResponse = (questionId, response) => {
    const existingResponseIndex = sessionNotesFormValue.responses.findIndex(
      (r) => r.question_id === questionId
    );

    if (existingResponseIndex !== -1) {
      // Update the existing response
      sessionNotesFormValue.responses[existingResponseIndex] = {
        ...sessionNotesFormValue.responses[existingResponseIndex],
        ...response,
      };
    } else {
      // Add a new response
      sessionNotesFormValue.responses.push({
        question_id: questionId,
        ...response,
      });
    }

    // Update the state variable
    setSessionNotesFormValues({ ...sessionNotesFormValue });
  };

  //Update Selection type question value
  const updateSelectionResponse = (index, selectedOptions) => {
    // Map selectedOptions to an array of option IDs
    const optionIds = selectedOptions.map((option) => option.id);

    updateResponse(sessionNotesForm[index].id, { option_ids: optionIds });
  };

  // Function to update a text response by index
  const updateTextResponse = (index, responseText) => {
    const newTextResponses = [...textResponses];
    newTextResponses[index] = responseText;
    setTextResponses(newTextResponses);
    updateResponse(sessionNotesForm[index].id, { response_text: responseText });
  };

  // Function to update a dropdown response by index

  const updateDropdownResponse = (index, selectedOption) => {
    const newDropdownResponses = [...dropdownResponses];
    newDropdownResponses[index] = selectedOption;
    setDropdownResponses(newDropdownResponses);
    updateResponse(sessionNotesForm[index].id, {
      option_ids: [selectedOption],
    });
  };

  // Function to update a multiselection response by index
  const updateMultiselectionResponse = (index, selectedValues) => {
    // Clone the current multiselectionResponses
    const newMultiselectionResponses = [...multiselectionResponses];

    // Update the selected values for the specific index
    newMultiselectionResponses[index] = selectedValues;

    // Update the state with the new array
    setMultiselectionResponses(newMultiselectionResponses);

    // Update the response in the sessionNotesFormValue
    const updatedResponse = selectedValues.map((value) => value.id);
    updateResponse(sessionNotesForm[index].id, { option_ids: updatedResponse });
  };

  const handleSaveSessionNote = async () => {
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/session-notes/submit`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(sessionNotesFormValue),
        }
      );
      const postResponse = await response.json();

      //console.log("postResponse", postResponse)
      //If success
      if (postResponse.code == 200) {
        handleSessionNotesClose();
        //Might add a loader state later

        setSessionNotesForm([]);
        setSessionNotesFormValues({});

        //Snackbar
        setSnackbarMessage("Session Note added successfully!");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  ///Session notes Region End
  const [isProceedDisabled, setIsProceedDisabled] = useState(true);

  useEffect(() => {
    // Check if form_id and appointment_date are not empty or null
    const isDisabled =
      !sessionNotesData.form_id || !sessionNotesData.appointment_date;
    setIsProceedDisabled(isDisabled);
  }, [sessionNotesData.form_id, sessionNotesData.appointment_date]);

  console.log("!sessionNotesData.form_id", sessionNotesData?.form_id);
  console.log(
    "!sessionNotesData.appointment_date",
    sessionNotesData?.appointment_date
  );
  const handleNavigation = (path) => {
    navigate(path); // You can use navigate to handle the routing instead of window.location.href
  };
  return (
    <>
      <div className="my-3 ClientDashboard">
        <div className="ClientDashboard--Header d-flex">
          <div className="d-flex Header--Left">
            <Breadcrumbs
              separator={<NavigateNext fontSize="small" />}
              aria-label="breadcrumb"
            >
              {/* Clients Link */}
              <Typography
                sx={{ cursor: "pointer", textTransform: "capitalize" }}
                color="textPrimary"
                onClick={() => {
                  window.location.href =
                    window.location.origin + location.pathname;
                }}
              >
                Patients
              </Typography>

              {/* Conditionally render client and screener */}
              {showClientDetails && client && (
                <Typography
                  sx={{ cursor: "pointer", textTransform: "capitalize" }}
                  color="textPrimary"
                  onClick={() => window.location.reload()}
                >
                  {client}
                </Typography>
              )}

              {/* Show screener if selected */}
              {selectedScreener && (
                <Typography color="textPrimary">
                  Screener <NavigateNext fontSize="small" />{" "}
                  {selectedScreener.title}
                </Typography>
              )}
            </Breadcrumbs>

            {/* Enable Later */}
            {/* {!showClientDetails && (
              <div style={{ maxWidth: "200px" }}>
                <AddAppointmentModal />
              </div>
            )} */}
          </div>
          {!showClientDetails && (
            <div className="d-flex Header--Right mt-3">
              <div
                style={{ background: "#f0f0f0" }}
                className="d-flex rounded-pill p-1 gap-2 Options"
              >
                <div
                  className={`Option--Item rounded-pill  py-2 px-3 ${
                    selectedTab == "All Patients" ? "SelectedItem" : ""
                  }`}
                  onClick={handletabSelect}
                >
                  All Patients
                </div>
                <div
                  className={`Option--Item rounded-pill  py-2 px-3 ${
                    selectedTab == "New Patients" ? "SelectedItem" : ""
                  }`}
                  onClick={handletabSelect}
                >
                  New Patients
                </div>
                <div
                  className={`Option--Item rounded-pill py-2 px-3  ${
                    selectedTab == "Follow-up" ? "SelectedItem" : ""
                  }`}
                  onClick={handletabSelect}
                >
                  Follow-up
                </div>
                {/* <div className="Option--Item">Clients by filters</div> */}
              </div>
            </div>
          )}
        </div>

        {selectedScreener == null ? (
          showClientDetails ? (
            <ClientDetailsView
              clientDetails={clientDetails}
              clientID={clientID}
              clientName={client}
              onScreenerView={handleViewScreener}
            />
          ) : (
            <>
              {isLoading ? (
                <TableContainer sx={{ marginTop: "20px" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Profile</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Sessions</TableCell>
                        {/* <TableCell>Notes</TableCell> */}
                        {/* <TableCell>Worksheets</TableCell> */}
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.from(new Array(10)).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "5vw" }}
                            />
                          </TableCell>
                          {/* <TableCell>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "15vw" }}
                            />
                          </TableCell> */}
                          {/* <TableCell>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "5vw" }}
                            />
                          </TableCell> */}
                          <TableCell>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "5vw" }}
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "15vw" }}
                            />
                          </TableCell>
                          <TableCell>
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "5vw" }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <>
                  <TableContainer sx={{ marginTop: "20px" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Profile</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Sessions</TableCell>
                          {/* <TableCell>Notes</TableCell> */}
                          {/* <TableCell>Worksheets</TableCell> */}
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredClients?.length === 0 ? (
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <div className="NoDataAvailable">
                                {NoDataSvg}
                                <p>No Patients Found</p>
                              </div>
                            </TableCell>
                            {/* <TableCell></TableCell> */}
                            {/* <TableCell></TableCell> */}
                            <TableCell></TableCell>
                          </TableRow>
                        ) : (
                          filteredClients.map((item) => (
                            <ClientDetailsTableRow
                              key={item.ID}
                              id={item.ID}
                              image={item.ProfileImage}
                              name={item.Name}
                              notes={item.notes}
                              screener={item.screner}
                              sessions={item.appointments_cnt}
                              type={item.client_type}
                              worksheets={item.worksheets}
                              country={item.Country}
                              onClickCallback={handleRowClick}
                              onAddNote={() =>
                                handleNotes(
                                  item.Name,
                                  item.ProfileImage,
                                  item.client_type,
                                  item.ID,
                                  item.AppDate,
                                  item.AppTime,
                                  item.UserId,
                                  item.Status
                                )
                              }
                              onAddSessionNote={() =>
                                handleSessionNotes(
                                  item.Name,
                                  item.ProfileImage,
                                  item.client_type,
                                  item.ID,
                                  item.Country,
                                  item.MobileNo,
                                  item.Address,
                                  item.State,
                                  item.City,
                                  item.Pincode,
                                  item.DOB
                                )
                              }
                              onPrescription={() =>
                                handlePrescription(
                                  item.Name,
                                  item.ProfileImage,
                                  item.client_type,
                                  item.ID,
                                  item.AppDate,
                                  item.AppTime
                                )
                              }
                              onCancel={() =>
                                handleCancelAppointment(
                                  item.Name,
                                  item.ProfileImage,
                                  item.client_type,
                                  item.ID,
                                  item.AppDate,
                                  item.AppTime
                                )
                              }
                            />
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {selectedTab == "All Patients" && (
                    <div className="d-flex justify-content-end w-100">
                      <Pagination
                        count={totalPage}
                        page={page}
                        onChange={handleChangePage}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )
        ) : (
          <ScreenerDetailsView
            clientDetails={clientDetails}
            clientID={clientID}
            clientName={client}
            type={selectedScreener.type}
            title={selectedScreener.title}
            screenerID={selectedScreener.id}
          />
        )}
      </div>

      {/* Notes Dialog */}
      <Dialog
        className="Dialog Notes--Dialog"
        open={openNotesModal}
        onClose={handleNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "98%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isNoteAdded !== null && !isNoteAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {/* {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "} */}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Write Something"
            multiline
            value={note}
            rows={4}
            fullWidth
            onChange={(e) => handleNote(e)}
            sx={{ marginTop: "20px" }}
          />

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostNote}
            disabled={note == "" ? true : false}
          >
            Add Note
          </Button>
        </DialogContent>
      </Dialog>

      {/* Session Notes Dialog */}
      <Dialog
        className="Dialog SessionNotes--Dialog"
        open={openSessionNotesModal}
        onClose={handleSessionNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "98%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {/* {
          (isNoteAdded !== null && !isNoteAdded) && (
            <div style={{ width: '100%', height: '100%', background: 'rgb(183 216 237 / 58%)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )
        } */}
        <DialogTitle>
          {sessionNotesStep == 1 && "Add Session Notes"}

          {sessionNotesStep == 2 && "Session Details"}
        </DialogTitle>
        <DialogContent className="Dialog--Content">
          {sessionNotesStep == 1 && (
            <>
              <FormControl fullWidth className="my-4 ClientSelect--Form">
                <InputLabel id="demo-simple-select-label">
                  Select Form
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sessionNotesData.form_id}
                  onChange={(e) => {
                    setSessionNotesData((prevData) => ({
                      ...prevData,
                      form_id: e.target.value,
                    }));
                  }}
                >
                  {formsList?.map((item) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Appointment Date"
                  format="YYYY-MM-DD"
                  value={dayjs(sessionNotesData.appointment_date, "YYYY-MM-DD")}
                  onChange={onSessionNoteAppiontmentDateChange}
                  sx={{ width: "100%" }}
                />

                <TextField
                  id="outlined-multiline-static"
                  label="Contact Number"
                  type="number"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  value={sessionNotesData.client_mobile}
                  fullWidth
                  sx={{ marginTop: "20px", marginBottom: "20px" }}
                  onChange={(e) => {
                    setSessionNotesData((prevData) => ({
                      ...prevData,
                      client_mobile: e.target.value,
                    }));
                  }}
                />

                <DatePicker
                  label="Client DOB"
                  format="YYYY-MM-DD"
                  value={dayjs(sessionNotesData.client_dob, "YYYY-MM-DD")}
                  onChange={onSessionNoteClientDOBDateChange}
                  sx={{ width: "100%" }}
                  disableFuture={true}
                />
              </LocalizationProvider>

              <TextField
                id="outlined-multiline-static"
                label="Clients Address"
                multiline
                value={sessionNotesData.client_address}
                fullWidth
                sx={{ marginTop: "20px" }}
                onChange={(e) => {
                  setSessionNotesData((prevData) => ({
                    ...prevData,
                    client_address: e.target.value,
                  }));
                }}
              />

              <div className="d-flex gap-2 my-4">
                <FormControl fullWidth className="ClientSelect--Form">
                  <InputLabel id="demo-simple-select-label">
                    Client State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sessionNotesData.client_state_id}
                    onChange={(e) => {
                      setSessionNotesData((prevData) => ({
                        ...prevData,
                        client_state_id: e.target.value,
                      }));
                    }}
                  >
                    {statesList?.map((item) => {
                      return (
                        <MenuItem key={item.code} value={item.id}>
                          <span>{item.name}</span>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  id="outlined-multiline-static"
                  label="Clients City"
                  multiline
                  value={sessionNotesData.client_city}
                  fullWidth
                  onChange={(e) => {
                    setSessionNotesData((prevData) => ({
                      ...prevData,
                      client_city: e.target.value,
                    }));
                  }}
                />

                <TextField
                  id="outlined-multiline-static"
                  label="Postal Code"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  type="number"
                  value={sessionNotesData.postal_code}
                  onChange={(e) => {
                    setSessionNotesData((prevData) => ({
                      ...prevData,
                      postal_code: e.target.value,
                    }));
                  }}
                  fullWidth
                />
              </div>

              <Button
                className="PrimaryCTA"
                variant="contained"
                disableElevation
                sx={{ margin: "30px auto 20px auto" }}
                onClick={handleProceed}
              >
                Proceed
              </Button>
            </>
          )}
          {sessionNotesStep == 2 && (
            <>
              {sessionNotesForm?.length > 0 &&
                sessionNotesForm?.map((question, i) => {
                  return (
                    <div className="mb-3 d-flex flex-column">
                      <b>
                        {i + 1}. {question.name}
                      </b>

                      {question.type == "text" && (
                        <TextField
                          id="outlined-multiline-static"
                          label="Enter text"
                          multiline
                          value={textResponses[i]}
                          fullWidth
                          rows={4}
                          sx={{ marginTop: "20px", marginBottom: "20px" }}
                          onChange={(e) => {
                            //console.log(e.target.value);
                            updateTextResponse(i, e.target.value);
                          }}
                        />
                      )}

                      {question.type == "dropdown" && (
                        <FormControl
                          fullWidth
                          className="ClientSelect--Form my-3"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Select
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={dropdownResponses[i] || ""}
                            onChange={(e) => {
                              const selectedOption = e.target.value;
                              updateDropdownResponse(i, selectedOption);
                            }}
                          >
                            {question?.options?.map((op) => (
                              <MenuItem value={op.id}>
                                <h6>{op.name}</h6>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      {question.type == "boolean" && (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          value={
                            sessionNotesFormValue?.responses?.find(
                              (r) => r.question_id === question.id
                            )?.option_ids[0] || ""
                          }
                          name={`radio-buttons-group-${i}`}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            // Update the response for boolean question with the option ID
                            updateResponse(question.id, {
                              option_ids: [selectedValue],
                            });
                          }}
                        >
                          {question?.options?.map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id.toString()} // Ensure the value is a string
                              control={<Radio />}
                              label={item.name}
                            />
                          ))}
                        </RadioGroup>
                      )}

                      {question.type == "selection" && (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          value={
                            sessionNotesFormValue?.responses?.find(
                              (r) => r.question_id === question.id
                            )?.option_ids[0] || ""
                          }
                          name={`radio-buttons-group-${i}`}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            // Update the response for boolean question with the option ID
                            updateResponse(question.id, {
                              option_ids: [selectedValue],
                            });
                          }}
                        >
                          {question?.options?.map((item) => (
                            <FormControlLabel
                              key={item.id}
                              value={item.id.toString()} // Ensure the value is a string
                              control={
                                <Radio
                                  icon={<CheckBoxOutlineBlankIcon />}
                                  checkedIcon={<CheckBoxIcon />}
                                />
                              }
                              label={item.name}
                            />
                          ))}
                        </RadioGroup>
                      )}
                      {/* {question.type == "selection" && (
                        <FormGroup>
                          {question?.options?.map((item) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={textResponses[
                                    i
                                  ]?.option_ids.includes(item.id)}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    const selectedValue = item.id;

                                    // Update the response for selection question with the option ID
                                    const updatedResponse =
                                      textResponses[i] || [];
                                    if (checked) {
                                      updatedResponse.push(selectedValue);
                                    } else {
                                      const index =
                                        updatedResponse.indexOf(selectedValue);
                                      if (index !== -1) {
                                        updatedResponse.splice(index, 1);
                                      }
                                    }

                                    updateResponse(question.id, {
                                      option_ids: updatedResponse,
                                    });
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          ))}
                        </FormGroup>
                      )} */}

                      {question.type === "multiselection" && (
                        <FormGroup>
                          {question.options.map((item) => {
                            const isSelected = multiselectionResponses[i]
                              ? multiselectionResponses[i]?.some(
                                  (value) => value.id === item.id
                                )
                              : false;

                            return (
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Checkbox
                                    checked={isSelected}
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      const selectedValue = item;

                                      const updatedResponse =
                                        multiselectionResponses[i]
                                          ? [...multiselectionResponses[i]]
                                          : [];

                                      if (checked) {
                                        updatedResponse.push(selectedValue);
                                      } else {
                                        const index = updatedResponse.findIndex(
                                          (value) =>
                                            value.id === selectedValue.id
                                        );
                                        if (index !== -1) {
                                          updatedResponse.splice(index, 1);
                                        }
                                      }

                                      // Call the updated function to update the response
                                      updateMultiselectionResponse(
                                        i,
                                        updatedResponse
                                      );
                                    }}
                                  />
                                }
                                label={item.name}
                              />
                            );
                          })}
                        </FormGroup>
                      )}

                      {question.type === "multiquestion" && (
                        <>
                          <TableContainer
                            component={Paper}
                            sx={{
                              boxShadow: "none",
                              marginTop: "15px",
                              border: "1px solid rgba(224, 224, 224, 1)",
                              borderRadius: "15px",
                            }}
                          >
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead sx={{ backgroundColor: "#73589b4f" }}>
                                {question?.sub_questions.map((item) => {
                                  return (
                                    <TableCell
                                      key={item.id}
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {item.name}
                                    </TableCell>
                                  );
                                })}
                              </TableHead>
                              <TableBody>
                                {multiQuestionRows?.map((row) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    {row.values?.map((item) => (
                                      <TableCell>
                                        {Object.values(item)}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <Button
                            variant="contained"
                            sx={{
                              width: "max-content",
                              marginTop: "15px",
                              textTransform: "capitalize",
                              borderRadius: "20px",
                            }}
                            startIcon={<AddIcon />}
                            onClick={() => {
                              setOpenSessionNotesTableModal(true);
                            }}
                          >
                            Add Row
                          </Button>
                        </>
                      )}
                    </div>
                  );
                })}

              <div className="d-flex align-items-center gap-2 justify-content-center mt-5">
                {/* <Button className="PrimaryCTA--Outlined">Save</Button> */}
                <Button
                  className="PrimaryCTA"
                  onClick={() => {
                    const multiItem = sessionNotesForm.filter(
                      (item) => item.type === "multiquestion"
                    );

                    //console.log("multiItem", multiItem[0]?.sub_questions);

                    if (
                      multiItem !== null &&
                      multiItem !== undefined &&
                      multiItem?.length > 0
                    ) {
                      const multiObj = {
                        question_id: multiItem[0].id,
                        values: [],
                      };

                      multiQuestionRows?.map((item) => {
                        //multiObj.values.push(item.values);

                        const formattedValues =
                          multiItem[0]?.sub_questions?.map((question, i) => {
                            const questionId = question.id;
                            const questionName = question.name;
                            return {
                              [questionId]: item.values[i][questionName],
                            };
                          });

                        //console.log("formattedValues", Object(formattedValues))
                        multiObj.values.push(formattedValues);
                      });

                      sessionNotesFormValue.responses.push(multiObj);
                      //console.log("multiObj", multiObj);
                    }

                    // console.log("sessionNotesFormValue", sessionNotesFormValue)

                    //Call API for saving session Note
                    handleSaveSessionNote();

                    //setSessionNotesStep(3)
                  }}
                >
                  Submit
                </Button>
              </div>
            </>
          )}

          {sessionNotesStep == 3 && (
            <>
              <div className="d-flex align-items-start gap-2">
                <InfoOutlinedIcon sx={{ fill: "var(--primary-base-color)" }} />
                <div>
                  <p style={{ fontWeight: "600", fontSize: "16px" }}>
                    You cannot edit once you submit.
                  </p>
                  <span style={{ fontWeight: "600", fontSize: "12px" }}>
                    Preview if you want to edit.
                  </span>
                </div>
              </div>
              <div
                className="d-flex align-items-center gap-3 justify-content-center"
                style={{ marginTop: "20px" }}
              >
                <Button
                  className="PrimaryCTAExp"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setSessionNotesStep(sessionNotesStep - 1);
                  }}
                >
                  Preview
                </Button>

                <Button
                  className="PrimaryCTA--Outlined"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setSessionNotesStep(sessionNotesStep + 1);
                  }}
                >
                  Submit Anyway
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* Add Table Data Dialog */}

      <Dialog
        className="Dialog SessionNotes--Dialog"
        open={openSessionNotesTableModal}
        onClose={() => {
          setOpenSessionNotesTableModal(false);
        }}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "98%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DialogTitle>Enter the details for new Table row</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div
            className="px-1 py-3"
            style={{
              display: "grid",
              gap: "10px",
            }}
          >
            {sessionNotesTableForm?.map((item) => (
              <TextField
                fullWidth
                id="outlined-basic"
                label={item.name}
                variant="outlined"
                value={textFieldsData[item.name] || ""}
                onChange={(e) => {
                  setTextFieldsData({
                    ...textFieldsData,
                    [item.name]: e.target.value,
                  });
                }}
              />
            ))}
          </div>

          <Button
            variant="contained"
            className="py-2 p-5"
            sx={{ textTransform: "capitalize", borderRadius: "10px" }}
            onClick={() => {
              const values = Object.entries(textFieldsData)?.map((item) => ({
                [item[0]]: item[1],
              }));

              const rowItem = { values: Object(values) };

              //console.log(rowItem)
              setMultiQuestionRows((prevRows) => [...prevRows, rowItem]);

              //Clear the text fields
              setTextFieldsData({});

              //Close the table popup
              setOpenSessionNotesTableModal(false);
            }}
          >
            Save
          </Button>
        </DialogContent>
      </Dialog>

      {/* Session Notes Dialog */}
      {openSessionNotesModal && (
        <Dialog
          className="Dialog SessionNotes--Dialog"
          open={openSessionNotesModal}
          onClose={handleSessionNotesClose}
          sx={{
            maxWidth: "610px",
            width: "98%",
            minWidth: "98%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {/* {
          (isNoteAdded !== null && !isNoteAdded) && (
            <div style={{ width: '100%', height: '100%', background: 'rgb(183 216 237 / 58%)', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )
        } */}
          <DialogTitle>
            {sessionNotesStep == 1 && "Add Session Notes"}

            {sessionNotesStep == 2 && "Session Details"}
          </DialogTitle>
          <DialogContent className="Dialog--Content">
            {sessionNotesStep == 1 && (
              <>
                <FormControl fullWidth className="my-4 ClientSelect--Form">
                  <InputLabel id="demo-simple-select-label">
                    Select Form
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sessionNotesData.form_id}
                    onChange={(e) => {
                      setSessionNotesData((prevData) => ({
                        ...prevData,
                        form_id: e.target.value,
                      }));
                    }}
                  >
                    {formsList?.map((item) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Appointment Date"
                    format="YYYY-MM-DD"
                    value={dayjs(
                      sessionNotesData.appointment_date,
                      "YYYY-MM-DD"
                    )}
                    onChange={onSessionNoteAppiontmentDateChange}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>

                <TextField
                  id="outlined-multiline-static"
                  label="Contact Number"
                  type="tel"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  value={sessionNotesData.client_mobile}
                  fullWidth
                  sx={{ marginTop: "20px", marginBottom: "20px" }}
                  onChange={(e) => {
                    setSessionNotesData((prevData) => ({
                      ...prevData,
                      client_mobile: e.target.value,
                    }));
                  }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Client DOB"
                    format="YYYY-MM-DD"
                    value={dayjs(sessionNotesData.client_dob, "YYYY-MM-DD")}
                    onChange={onSessionNoteClientDOBDateChange}
                    sx={{ width: "100%" }}
                    disableFuture={true} // This will disable future dates
                    maxDate={dayjs()} // This will set the maximum selectable date to today
                  />
                </LocalizationProvider>

                <TextField
                  id="outlined-multiline-static"
                  label="Clients Address"
                  multiline
                  value={sessionNotesData.client_address}
                  fullWidth
                  sx={{ marginTop: "20px" }}
                  onChange={(e) => {
                    setSessionNotesData((prevData) => ({
                      ...prevData,
                      client_address: e.target.value,
                    }));
                  }}
                />

                <div className="d-flex gap-2 my-4">
                  <FormControl fullWidth className="ClientSelect--Form">
                    <InputLabel id="demo-simple-select-label">
                      Client State
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sessionNotesData.client_state_id}
                      onChange={(e) => {
                        setSessionNotesData((prevData) => ({
                          ...prevData,
                          client_state_id: e.target.value,
                        }));
                      }}
                    >
                      {statesList?.map((item) => {
                        return (
                          <MenuItem key={item.code} value={item.id}>
                            <span>{item.name}</span>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <TextField
                    id="outlined-multiline-static"
                    label="Clients City"
                    multiline
                    value={sessionNotesData.client_city}
                    fullWidth
                    onChange={(e) => {
                      setSessionNotesData((prevData) => ({
                        ...prevData,
                        client_city: e.target.value,
                      }));
                    }}
                  />

                  <TextField
                    id="outlined-multiline-static"
                    label="Postal Code"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    type="number"
                    value={sessionNotesData.postal_code}
                    onChange={(e) => {
                      setSessionNotesData((prevData) => ({
                        ...prevData,
                        postal_code: e.target.value,
                      }));
                    }}
                    fullWidth
                  />
                </div>

                <Button
                  className="PrimaryCTA"
                  variant="contained"
                  disableElevation
                  sx={{ margin: "30px auto 20px auto" }}
                  onClick={handleProceed}
                  disabled={isProceedDisabled}
                >
                  Proceed
                </Button>
              </>
            )}
            {sessionNotesStep == 2 && (
              <>
                {sessionNotesForm?.length > 0 &&
                  sessionNotesForm?.map((question, i) => {
                    return (
                      <div className="mb-3 d-flex flex-column">
                        <b>
                          {i + 1}. {question.name}
                        </b>

                        {question.type == "text" && (
                          <TextField
                            id="outlined-multiline-static"
                            label="Enter text"
                            multiline
                            value={textResponses[i]}
                            fullWidth
                            rows={4}
                            sx={{ marginTop: "20px", marginBottom: "20px" }}
                            onChange={(e) => {
                              //console.log(e.target.value);
                              updateTextResponse(i, e.target.value);
                            }}
                          />
                        )}

                        {question.type == "dropdown" && (
                          <FormControl
                            fullWidth
                            className="ClientSelect--Form my-3"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Select
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={dropdownResponses[i] || ""}
                              onChange={(e) => {
                                const selectedOption = e.target.value;
                                updateDropdownResponse(i, selectedOption);
                              }}
                            >
                              {question?.options?.map((op) => (
                                <MenuItem value={op.id}>
                                  <h6>{op.name}</h6>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                        {question.type == "boolean" && (
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            value={
                              sessionNotesFormValue?.responses?.find(
                                (r) => r.question_id === question.id
                              )?.option_ids[0] || ""
                            }
                            name={`radio-buttons-group-${i}`}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              // Update the response for boolean question with the option ID
                              updateResponse(question.id, {
                                option_ids: [selectedValue],
                              });
                            }}
                          >
                            {question?.options?.map((item) => (
                              <FormControlLabel
                                key={item.id}
                                value={item.id.toString()} // Ensure the value is a string
                                control={<Radio />}
                                label={item.name}
                              />
                            ))}
                          </RadioGroup>
                        )}

                        {question.type == "selection" && (
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            value={
                              sessionNotesFormValue?.responses?.find(
                                (r) => r.question_id === question.id
                              )?.option_ids[0] || ""
                            }
                            name={`radio-buttons-group-${i}`}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              // Update the response for boolean question with the option ID
                              updateResponse(question.id, {
                                option_ids: [selectedValue],
                              });
                            }}
                          >
                            {question?.options?.map((item) => (
                              <FormControlLabel
                                key={item.id}
                                value={item.id.toString()} // Ensure the value is a string
                                control={
                                  <Radio
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                  />
                                }
                                label={item.name}
                              />
                            ))}
                          </RadioGroup>
                        )}
                        {/* {question.type == "selection" && (
                        <FormGroup>
                          {question?.options?.map((item) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={textResponses[
                                    i
                                  ]?.option_ids.includes(item.id)}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    const selectedValue = item.id;

                                    // Update the response for selection question with the option ID
                                    const updatedResponse =
                                      textResponses[i] || [];
                                    if (checked) {
                                      updatedResponse.push(selectedValue);
                                    } else {
                                      const index =
                                        updatedResponse.indexOf(selectedValue);
                                      if (index !== -1) {
                                        updatedResponse.splice(index, 1);
                                      }
                                    }

                                    updateResponse(question.id, {
                                      option_ids: updatedResponse,
                                    });
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          ))}
                        </FormGroup>
                      )} */}

                        {question.type === "multiselection" && (
                          <FormGroup>
                            {question.options.map((item) => {
                              const isSelected = multiselectionResponses[i]
                                ? multiselectionResponses[i]?.some(
                                    (value) => value.id === item.id
                                  )
                                : false;

                              return (
                                <FormControlLabel
                                  key={item.id}
                                  control={
                                    <Checkbox
                                      checked={isSelected}
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        const selectedValue = item;

                                        const updatedResponse =
                                          multiselectionResponses[i]
                                            ? [...multiselectionResponses[i]]
                                            : [];

                                        if (checked) {
                                          updatedResponse.push(selectedValue);
                                        } else {
                                          const index =
                                            updatedResponse.findIndex(
                                              (value) =>
                                                value.id === selectedValue.id
                                            );
                                          if (index !== -1) {
                                            updatedResponse.splice(index, 1);
                                          }
                                        }

                                        // Call the updated function to update the response
                                        updateMultiselectionResponse(
                                          i,
                                          updatedResponse
                                        );
                                      }}
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                          </FormGroup>
                        )}

                        {question.type === "multiquestion" && (
                          <>
                            <TableContainer
                              component={Paper}
                              sx={{
                                boxShadow: "none",
                                marginTop: "15px",
                                border: "1px solid rgba(224, 224, 224, 1)",
                                borderRadius: "15px",
                              }}
                            >
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead
                                  sx={{ backgroundColor: "#73589b4f" }}
                                >
                                  {question?.sub_questions.map((item) => {
                                    return (
                                      <TableCell
                                        key={item.id}
                                        sx={{ textTransform: "capitalize" }}
                                      >
                                        {item.name}
                                      </TableCell>
                                    );
                                  })}
                                </TableHead>
                                <TableBody>
                                  {multiQuestionRows?.map((row) => (
                                    <TableRow
                                      key={row.name}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      {row.values?.map((item) => (
                                        <TableCell>
                                          {Object.values(item)}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <Button
                              variant="contained"
                              sx={{
                                width: "max-content",
                                marginTop: "15px",
                                textTransform: "capitalize",
                                borderRadius: "20px",
                              }}
                              startIcon={<AddIcon />}
                              onClick={() => {
                                setOpenSessionNotesTableModal(true);
                              }}
                            >
                              Add Row
                            </Button>
                          </>
                        )}
                      </div>
                    );
                  })}

                <div className="d-flex align-items-center gap-2 justify-content-center mt-5">
                  {/* <Button className="PrimaryCTA--Outlined">Save</Button> */}
                  <Button
                    className="PrimaryCTA"
                    onClick={() => {
                      const multiItem = sessionNotesForm.filter(
                        (item) => item.type === "multiquestion"
                      );

                      //console.log("multiItem", multiItem[0]?.sub_questions);

                      if (
                        multiItem !== null &&
                        multiItem !== undefined &&
                        multiItem?.length > 0
                      ) {
                        const multiObj = {
                          question_id: multiItem[0].id,
                          values: [],
                        };

                        multiQuestionRows?.map((item) => {
                          //multiObj.values.push(item.values);

                          const formattedValues =
                            multiItem[0]?.sub_questions?.map((question, i) => {
                              const questionId = question.id;
                              const questionName = question.name;
                              return {
                                [questionId]: item.values[i][questionName],
                              };
                            });

                          //console.log("formattedValues", Object(formattedValues))
                          multiObj.values.push(formattedValues);
                        });

                        sessionNotesFormValue.responses.push(multiObj);
                        //console.log("multiObj", multiObj);
                      }

                      // console.log("sessionNotesFormValue", sessionNotesFormValue)

                      //Call API for saving session Note
                      handleSaveSessionNote();

                      //setSessionNotesStep(3)
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}

            {sessionNotesStep == 3 && (
              <>
                <div className="d-flex align-items-start gap-2">
                  <InfoOutlinedIcon
                    sx={{ fill: "var(--primary-base-color)" }}
                  />
                  <div>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>
                      You cannot edit once you submit.
                    </p>
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      Preview if you want to edit.
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center gap-3 justify-content-center"
                  style={{ marginTop: "20px" }}
                >
                  <Button
                    className="PrimaryCTA"
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      setSessionNotesStep(sessionNotesStep - 1);
                    }}
                  >
                    Preview
                  </Button>

                  <Button
                    className="PrimaryCTA--Outlined"
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      setSessionNotesStep(sessionNotesStep + 1);
                    }}
                  >
                    Submit Anyway
                  </Button>
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
      )}

      {/* Add Table Data Dialog */}
      {openSessionNotesTableModal && (
        <Dialog
          className="Dialog SessionNotes--Dialog"
          open={openSessionNotesTableModal}
          onClose={() => {
            setOpenSessionNotesTableModal(false);
          }}
          sx={{
            maxWidth: "610px",
            width: "98%",
            minWidth: "98%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <DialogTitle>Enter the details for new table row</DialogTitle>
          <DialogContent className="Dialog--Content">
            <div
              className="px-1 py-3"
              style={{
                display: "grid",
                gap: "10px",
              }}
            >
              {sessionNotesTableForm?.map((item) => (
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label={item.name}
                  variant="outlined"
                  value={textFieldsData[item.name] || ""}
                  onChange={(e) => {
                    setTextFieldsData({
                      ...textFieldsData,
                      [item.name]: e.target.value,
                    });
                  }}
                />
              ))}
            </div>

            <Button
              variant="contained"
              className="py-2 p-5"
              sx={{ textTransform: "capitalize", borderRadius: "10px" }}
              onClick={() => {
                const values = Object.entries(textFieldsData)?.map((item) => ({
                  [item[0]]: item[1],
                }));

                const rowItem = { values: Object(values) };

                //console.log(rowItem)
                setMultiQuestionRows((prevRows) => [...prevRows, rowItem]);

                //Clear the text fields
                setTextFieldsData({});

                //Close the table popup
                setOpenSessionNotesTableModal(false);
              }}
            >
              Save
            </Button>
          </DialogContent>
        </Dialog>
      )}

      {/* Precriptions Dialog */}
      <Dialog
        className="Dialog Prescription--Dialog"
        open={openPrescriptionModal}
        onClose={handlePrescriptionClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isPrescriptionAdded !== null && !isPrescriptionAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Add Prescription</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {/* {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "} */}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Prescription Name"
            value={prescriptionName}
            onChange={handlePrescriptionName}
            fullWidth
            sx={{ marginTop: "20px" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Prescription notes"
            value={prescriptionNote}
            onChange={handlePrescriptionNote}
            multiline
            rows={4}
            fullWidth
            sx={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <div
            className="upload-button"
            style={{ marginTop: "0", height: "100%" }}
          >
            <label
              htmlFor="file-upload"
              className="upload-button-label"
              style={{ height: "100%", fontSize: "16px" }}
            >
              <FileUploadOutlinedIcon className="upload-button-icon" />
              Upload
            </label>
            {isFileLoading !== null && isFileLoading ? (
              <div className="d-flex align-items-center mx-auto">
                <CircularProgress
                  sx={{ width: "20px !important", height: "20px !important" }}
                />
              </div>
            ) : (
              <>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={handleFileSelect}
                  className="upload-button-input"
                />
                {selectedFiles && (
                  <div className="selected-files">
                    <div className="file-name">{selectedFiles}</div>
                  </div>
                )}
              </>
            )}
          </div>

          <Button
            className="PrimaryCTA"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostPrescription}
            disabled={
              prescriptionName == "" || selectedFiles === "" ? true : false
            }
          >
            Add Prescription
          </Button>
        </DialogContent>
      </Dialog>

      {/* Add Appointment Dialog */}
      {/* <Dialog
        className="AddApointment--Dialog"
        open={open}
        onClose={handleClose}
        sx={{
          maxWidth: "610px",
          width: "100%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DialogTitle>Add Appointment</DialogTitle>
        <DialogContent className="Dialog--Content mt-2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Pick a date"
              format="DD/MM/YYYY"
              value={dayjs(clientData?.appointmentDate, "DD/MM/YYYY")}
              onChange={onToDateChange}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>

          <div className="d-flex gap-1 SlotTiming">
            <p className={`${!checked ? "SelectedSlot" : ""}`}>Morning Slots</p>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <p className={`${checked ? "SelectedSlot" : ""}`}>Evening Slots</p>
          </div>

          <Grid
            container
            gap={2}
            sx={{ width: "100%", margin: "0" }}
            className="Timings"
          >
            {slotsList
              ?.filter((item) => {
                if (!checked) {
                  // Render morning slots (AM)
                  return item.slot.split(" - ")[0].includes("AM");
                } else {
                  // Render evening slots (PM)
                  return item.slot.split(" - ")[0].includes("PM");
                }
              })
              .map((item) =>
                item.availability ? (
                  <Grid
                    item
                    xs={2}
                    key={item.slot}
                    className={`AvailableSlot ${
                      item.id == clientData?.slotID ? "SelectedSlot" : ""
                    }`}
                    onClick={() => handleSlotChange(item)}
                  >
                    <p>{item.slot.split(" - ")[0]}</p>
                  </Grid>
                ) : (
                  <Grid item xs={2} key={item.slot}>
                    <p>{item.slot.split(" - ")[0]}</p>
                  </Grid>
                )
              )}
          </Grid>

          <div className="SessionDuration--Container">
            <h6>Session Duration</h6>
            <Grid
              container
              gap={2}
              sx={{ width: "100%", margin: "0" }}
              className="SessionTimings"
            >
              

              <Grid
                item
                xs={3}
                className="SelectedSTiming"
                //onClick={() => handleSlotTimingChange(30)}
              >
                <p>30 minutes</p>
              </Grid>
            </Grid>
          </div>

          <FormControl fullWidth className="mt-3 ClientSelect--Form">
            <InputLabel id="demo-simple-select-label">Select Client</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={client}
              onChange={handleClientSelect}
            >
              {clientList?.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  <div
                    id={item.id}
                    className="d-flex gap-2 ClientSelect--MenuItem"
                  >
                    <img
                      src={item.image}
                      width={45}
                      height={45}
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                    <div>
                      <h6>{item.name}</h6>
                      <p>{item.country}</p>
                    </div>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="ConfirmBooking"
            sx={{ marginLeft: "auto", marginRight: "auto" }}
            onClick={handleClose}
            variant="contained"
            disableElevation
          >
            Confirm and Book Appointment
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ExpertClientDB;
