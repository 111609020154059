import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useState, useEffect, useRef, useContext } from "react";

import AllSessions from "./cards/AllSessions";
import MarkedSessions from "./cards/MarkedSessions";
import axios from "axios";
import { Config } from "../../core/_request";

import Skeleton from "@mui/material/Skeleton";
// import Pagination from "./Pagination/pagination";
import { useNavigate } from "react-router-dom";
import SessionStatsCard from "./cards/SessionStatsCard";
import SessionStatGraphCard from "./cards/SessionStatGraphCard";

import { AppContext } from "../../context";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

//Tabs
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SessionPrescriptionTabItem from "./tabs/sessionDashboard/SessionPrescriptionTabItem";
import SessionNote from "./tabs/sessionDashboard/SessionNote";
//import TabList from "@mui/lab/TabList";
//import TabPanel from "@mui/lab/TabPanel";

import ReconnectingWebSocket from "reconnecting-websocket";
import SessionTranscript from "./tabs/sessionDashboard/SessionTranscript";
import FollowUpSummary from "./tabs/sessionDashboard/FollowUpSummary";
import { Button, Pagination } from "@mui/material";
import CaseSummary from "./tabs/sessionDashboard/CaseSummary";

import CachedIcon from "@mui/icons-material/Cached";

const websocketDefaultData = {
  headers: {
    session_id: "",
    appoinment_id: "",
    user_id: "",
    expert_id: "",
    appointment_date: "",
    booking_date: "",
  },
  session_data: {
    joining_details: {
      expert_join_time: null,
      user_join_time: null,
      is_user_on_time: true,
      is_expert_on_time: true,
      expert_signout_time: null,
      user_signout_time: null,
      total_session_duration: "",
      user_session_duration: "",
      expert_session_duration: "",
    },
    emotion_details: [
      {
        user: [
          {
            happy: "",
            sad: "",
            neutral: "",
          },
        ],
        expert: [
          {
            happy: "",
            sad: "",
            neutral: "",
          },
        ],
      },
    ],
    connectivity_details: {
      user: "",
      expert: "",
    },

    languages_spoken: {
      En: "",
      Hi: "",
    },
    engagement_details: {
      user_engaged: "",
      expert_engaged: "",
      both_engaged: "",
      none_engaged: "",
    },
    demographics: {
      age: "",
      sex: "",
    },
    prescription: [
      {
        chief_complaints: [
          {
            timestamp: "",
            blob: "",
          },
        ],
        vitals: [
          {
            timestamp: "",
            blob: "",
          },
        ],
        diagnosis: [
          {
            timestamp: "",
            blob: "",
          },
        ],
        advise: [
          {
            timestamp: "",
            blob: "",
          },
        ],
        follow_up_date: "",
        medicines: [
          {
            id: "",
            prescribed_medicines: [],
          },
        ],
      },
    ],
    session_summary: [
      {
        summary_details: [
          {
            timestamp: "",
            blob: "",
          },
        ],
      },
    ],
    lab_test: [
      {
        prescribed_lab_test: [
          {
            timestamp: "",
            test_name: "",
            sample_requirement: "",
            storage_transport_temperature: "",
            acceptable_alternative_specimen: "",
            other_requirements: "",
            causes_for_rejection: "",
            laboratory_services: "",
            labtest_code: "",
            time_to_result: "",
          },
        ],
      },
    ],
  },
};

function formatTimestampTo12Hour(timestamp) {
  const formattedTime = new Date(timestamp).toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  if (formattedTime === "Invalid Date") {
    return "--:--";
  }
  return formattedTime;
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SessionManagement = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [getSessionManagement, setSessionManagement] = useState({});
  const [session, setSession] = useState("all");
  const [loading, setLoading] = useState(true);
  const [currentPage, setcurrPage] = useState(0);
  const [sessionView, setSessionView] = useState("default");
  const [selectedTab, setSelectedTab] = useState(0);
  const [websocketData, setwebsocketData] = useState(websocketDefaultData);
  const [isWebsocketDataRecieved, setIsWebsocketDataRecieved] = useState(false);
  const [sessionDashboardData, setSessionDashboardData] = useState({});
  const [pingInterval, setPingInterval] = useState(true);
  const [height, setHeight] = useState(210);
  const [width, setWidth] = useState(240);
  const [columns, setColumns] = useState();

  const searchBarParams = new URLSearchParams(window.location.search);
  let componentView = searchBarParams.get("view");
  const { expertDashboardData, setExpertDashboardData } =
    useContext(AppContext);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  // console.log("componentView", componentView);
  const socketRef = useRef(null);

  let tempData = websocketData;
  let incrementalTime = 1000;

  let clientID = searchBarParams.get("patientID");
  let appointmentID = searchBarParams.get("appointmentID");
  const session_status = searchBarParams.get("completed_flag");

  useEffect(() => {
    handleSessionManagement("all");
    showModelPop();
  }, []);

  useEffect(() => {
    handleSessionManagement(session);
  }, [currentPage]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let columns;

      if (session === "all") {
        if (screenWidth >= 1000) {
          columns = 4;
          setWidth(240);
          setHeight(210);
        } else {
          columns = 2;
          setWidth(340);
          setHeight(220);
        }
      } else {
        // Assuming session is either "completed" or "in-complete"
        if (screenWidth >= 1300) {
          columns = 4;
          setWidth(300);
          setHeight(100);
        } else if (screenWidth < 1300 && screenWidth > 1000) {
          columns = 3;
          setWidth(280);
          setHeight(100);
        } else {
          columns = 2;
          setWidth(240);
          setHeight(220);
        }
      }

      setColumns(columns);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Call handleResize initially to set the initial state based on the window size
    handleResize();

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [session]);

  useEffect(() => {
    const storedData = localStorage.getItem("Authorization");

    if (componentView == null) {
      setSessionView("default");
      setSessionDashboardData({});
      setExpertDashboardData({});
      //
    } else {
      setSessionView("sessionDashboard");

      setSessionDashboardData((prevData) => ({
        ...prevData,
        clientID,
        appointmentID,
      }));
      setExpertDashboardData((prevData) => ({
        ...prevData,
        session_data: {
          ...prevData.session_data,
          submitted: session_status,
        },
      }));

      //Connect to web socket
      //Create a new WebSocket connection
      // &appointment_id=${appointment_ID}
      socketRef.current = new ReconnectingWebSocket(
        `wss://session-note.uwc.world/ws?authorization=${storedData.split("Bearer ")[1]
        }&appointment_id=${appointmentID}`
      );

      //Event handler for when the connection is opened
      socketRef.current.addEventListener("open", (event) => {
        console.log("WebSocket connection opened!");

        // Ensure that the socketRef.current is not null

        // You can include additional logic here if needed
      });

      //Event handler for when a message is received from the server

      socketRef.current.addEventListener("message", (event) => {
        console.log("Message received from server!");

        try {
          // Parse the received JSON data
          let resp_data = JSON.parse(event.data);
          // Update the state using the functional form of setState
          // console.log(resp_data);

          setExpertDashboardData((prevData) => {
            if (JSON.stringify(tempData) !== JSON.stringify(resp_data)) {
              return {
                ...prevData,

                session_data: {
                  appointment_details: {
                    session_id: resp_data?.header?.session_id
                      ? resp_data?.header?.session_id
                      : "",
                    appointment_id: resp_data?.header?.appointment_id || "",
                    user_id: resp_data?.header?.user_id || "",
                    expert_id: resp_data?.header?.expert_id || "",
                    appointment_date: resp_data?.header?.appointment_date || "",
                    booking_date: resp_data?.header?.booking_date || "",
                  },
                  joining_details: {
                    client_name:
                      resp_data?.session_data?.joining_details
                        ?.client_name || "",
                    expert_join_time:
                      resp_data?.session_data?.joining_details
                        ?.expert_join_time || "",
                    user_join_time:
                      resp_data?.session_data?.joining_details
                        ?.user_join_time || "",
                    is_user_on_time:
                      resp_data?.session_data?.joining_details
                        ?.is_user_on_time || "",
                    is_expert_on_time:
                      resp_data?.session_data?.joining_details
                        ?.is_expert_on_time || "",
                    expert_signout_time:
                      resp_data?.session_data?.joining_details
                        ?.expert_signout_time || "",
                    user_signout_time:
                      resp_data?.session_data?.joining_details
                        ?.user_signout_time || "",
                    total_session_duration:
                      resp_data?.session_data?.joining_details
                        ?.total_session_duration || "",
                    user_session_duration:
                      resp_data?.session_data?.joining_details
                        ?.user_session_duration || "",
                    expert_session_duration:
                      resp_data?.session_data?.joining_details
                        ?.expert_session_duration || "",
                  },

                  emotion_details:
                    resp_data?.session_data?.emotion_details.length !== 0
                      ? resp_data?.session_data?.emotion_details
                      : [
                        {
                          user: [{}],
                        },
                      ],

                  connectivity_details: {
                    user:
                      resp_data?.session_data?.connectivity_details?.user || "",
                    expert:
                      resp_data?.session_data?.connectivity_details?.expert ||
                      "",
                  },
                  languages_spoken:
                    resp_data?.session_data?.languages_spoken || {},
                  engagement_details:
                    resp_data?.session_data?.engagement_details || {},

                  demographics: {
                    age: resp_data?.session_data?.demographics?.age || "",
                    sex: resp_data?.session_data?.demographics?.sex || "",
                  },
                  chief_complaints:
                    resp_data?.session_data?.prescription[0]
                      ?.chief_complaints || [],
                  vitals:
                    resp_data?.session_data?.prescription[0]?.vitals || [],
                  diagnosis:
                    resp_data?.session_data?.prescription[0]?.diagnosis || [],
                  advise:
                    resp_data?.session_data?.prescription[0]?.advise || [],
                  follow_up_date:
                    resp_data?.session_data?.prescription[0]?.follow_up_date ||
                    "",
                  prescribed_medicines: resp_data?.session_data?.prescription[0]
                    ?.medicines[0]?.prescribed_medicines || [
                      {
                        drug: "",
                        dosage: "",
                        form: "",
                        route: "",
                        frequency: "",
                        strength: "",
                        duration: "",
                      },
                    ],
                  session_summary:
                    resp_data?.session_data?.session_summary[0]
                      ?.summary_details || [],
                  lab_test: resp_data?.session_data?.lab_test[0]
                    ?.prescribed_lab_test || [
                      {
                        timestamp: "",
                        test_name: "",
                        sample_requirement: "",
                        storage_transport_temperature: "",
                        acceptable_alternative_specimen: "",
                        other_requirements: "",
                        causes_for_rejection: "",
                        laboratory_services: "",
                        labtest_code: "",
                        time_to_result: "",
                      },
                    ],
                  follow_up_summary:
                    resp_data?.session_data?.follow_up_summary || {},
                  case_summary: resp_data?.session_data?.case_summary || {},
                  session_note: resp_data?.session_data?.session_note || [],
                  session_transcript:
                    resp_data?.session_data?.session_transcript || {},
                  on_camera_details: resp_data?.session_data
                    ?.on_camera_details || {
                    expert_camera_percent: "",
                    user_camera_percent: "",
                  },
                  submitted:
                    session_status ||
                    resp_data?.session_data?.submitted ||
                    false,
                },
              };
            } else return prevData;
          });
          setwebsocketData(resp_data);
          if (JSON.stringify(tempData) !== JSON.stringify(resp_data)) {
            tempData = resp_data;
          }

          setPingInterval(false);
          setIsWebsocketDataRecieved(true);
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      });

      //Event handler for when the connection is closed
      socketRef.current.addEventListener("close", (event) => {
        console.log("WebSocket connection closed!");
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null; // Reset the ref when the component unmounts
      }
    };
  }, [componentView, appointmentID]);

  useEffect(() => {
    let timeoutId;

    if (socketRef?.current && pingInterval) {
      // send ping message after every 5 seconds
      function sendPing() {
        incrementalTime *= 2;
        if (socketRef.current.readyState === WebSocket.OPEN) {
          socketRef.current.send("ping");
          // console.log("sent", "ping");
        }
        timeoutId = setTimeout(sendPing, incrementalTime);
      }

      if (pingInterval) {
        sendPing();
      }
    }

    // Clear the timeout when the component is unmounted or when dependencies change
    return () => clearTimeout(timeoutId);
  }, [socketRef?.current, pingInterval]);
  // console.log("sessionDashboardData", expertDashboardData);

  const handleOpenModal1 = () => {
    setOpenModal1(true);
  };

  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };

  const handleCloseModal1 = () => {
    setOpenModal1(false);
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModal3 = () => {
    setOpenModal3(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModal3 = () => {
    setOpenModal3(false);
  };
  // console.log("sample Data",SAMPLEDATA);
  const showModelPop = () => {
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_id: userId,
      user_type: "expert",
      current_time: getCurrentTime(),
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-feedback-popup`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          setLoading(false);
        }
      });
  };

  const handleSessionManagement = (sessions) => {
    let body = null;
    setLoading(true);
    let limit = 12;
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    if (sessions === "all") {
      body = {
        expert_id: userId,
        all_session: true,
        current_time: getCurrentTime(),
        page: currentPage,
        limit: limit,
      };
    } else if (sessions === "completed") {
      body = {
        expert_id: userId,
        completed: true,
        current_time: getCurrentTime(),
        page: currentPage,
        limit: limit,
      };
    } else if (sessions === "in-complete") {
      body = {
        expert_id: userId,
        incompleted: true,
        current_time: getCurrentTime(),
        page: currentPage,
        limit: limit,
      };
    }

    setSession(sessions);
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-list`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          setSessionManagement(resp.data[0]);
          setLoading(false);
        }
      });
  };

  const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleSubmit = () => {
    if (socketRef.current.readyState === WebSocket.OPEN) {
      const socketData = {
        header: expertDashboardData.session_data.appointment_details,
        session_data: {
          submitted: true,
          joining_details: expertDashboardData.session_data.joining_details,
          emotion_details: expertDashboardData.session_data.emotion_details,
          connectivity_details:
            expertDashboardData.session_data.connectivity_details,
          languages_spoken: expertDashboardData.session_data.languages_spoken,
          engagement_details:
            expertDashboardData.session_data.engagement_details,
          demographics: expertDashboardData.session_data.demographics,
          on_camera_details: expertDashboardData.session_data.on_camera_details,
          prescription: [
            {
              chief_complaints:
                expertDashboardData.session_data.chief_complaints,
              vitals: expertDashboardData.session_data.vitals,
              diagnosis: expertDashboardData.session_data.diagnosis,
              advise: expertDashboardData.session_data.advise,
              follow_up_date: expertDashboardData.session_data.follow_up_date,
              medicines: [
                {
                  prescribed_medicines:
                    expertDashboardData.session_data.prescribed_medicines,
                },
              ],
            },
          ],
          session_summary: [
            {
              summary_details: expertDashboardData.session_data.session_summary,
            },
          ],
          lab_test: [
            { prescribed_lab_test: expertDashboardData.session_data.lab_test },
          ],
          session_note: expertDashboardData.session_data.session_note,
          follow_up_summary: expertDashboardData.session_data.follow_up_summary,
          case_summary: expertDashboardData.session_data.case_summary,
          session_transcript:
            expertDashboardData.session_data.session_transcript,
        },
      };
      socketRef.current.send(JSON.stringify(socketData));
      // console.log("Data sent to WebSocket:", JSON.stringify(socketData));
      //setExpertDashboardData()
    } else {
      console.error("WebSocket connection not open.");
    }
  };


  return (
    <div>
      {sessionView === "default" ? (
        <>
          <div
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "22px",
              color: "#000000",
            }}
          >
            Session Management
          </div>
          <div>
            <div style={{ background: "#f0f0f0", maxWidth: "410px", fontSize: "14px", }} className="d-flex p-1   rounded-pill gap-2 mt-2">
              <button
                onClick={() => {
                  handleSessionManagement("all");
                  setcurrPage(0);
                }}
                className="rounded-pill border-0 px-3 py-2  fw-bold "
                style={{
                  background: session === "all" ? "#ffffff" : "",
                  color: session === "all" ? "#212529" : "#6a6c6a",
                }}
              >
                <span style={{ flex: 1, whiteSpace: "nowrap" }}>
                  Unmarked Sessions
                </span>
              </button>
              <button
                onClick={() => {
                  handleSessionManagement("completed");
                  setcurrPage(0);
                }}
                className="rounded-pill border-0 px-3 py-2  fw-bold "
                style={{
                  background: session === "completed" ? "#ffffff" : "",
                  color: session === "completed" ? "#212529" : "#6a6c6a",
                }}
              >
                Completed
              </button>
              <button
                onClick={() => {
                  handleSessionManagement("in-complete");
                  setcurrPage(0);
                }}
                className="rounded-pill border-0 px-3 py-2  fw-bold "
                style={{
                  background: session === "in-complete" ? "#ffffff" : "",
                  color: session === "in-complete" ? "#212529" : "#6a6c6a",
                }}
              // onMouseEnter={(e) => {
              //   e.target.style.color = "white";
              // }}
              // onMouseLeave={(e) => {
              //   e.target.style.color = "black";
              // }}
              >
                No Show
              </button>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              borderRadius: "20px",
              marginTop: "25px",
            }}
          >
            {session === "all" && (
              <AllSessions
                session={session}
                unMarkedSession={getSessionManagement?.unmarked_session}
                openModal={openModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                openModal1={openModal1}
                handleOpenModal1={handleOpenModal1}
                handleCloseModal1={handleCloseModal1}
                loading={loading}
                action={() => {
                  setTimeout(() => {
                    handleSessionManagement("all");
                    setcurrPage(0);
                  }, 4000);

                }}
              />
            )}
            {session === "completed" && (
              <MarkedSessions
                openModal={openModal3}
                handleOpenModal={handleOpenModal3}
                handleCloseModal={handleCloseModal3}
                openModal1={openModal2}
                handleOpenModal1={handleOpenModal2}
                handleCloseModal1={handleCloseModal2}
                session={session}
                markedSessions={
                  session === "completed"
                    ? getSessionManagement?.completed_session
                    : getSessionManagement?.marked_session
                }
                loading={loading}
              />
            )}
            {session === "in-complete" && (
              <MarkedSessions
                openModal={openModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
                openModal1={openModal2}
                handleOpenModal1={handleOpenModal2}
                handleCloseModal1={handleCloseModal2}
                session={session}
                markedSessions={
                  session === "in-complete"
                    ? getSessionManagement?.incompleted_session
                    : getSessionManagement?.marked_session
                }
                loading={loading}
              />
            )}
            {loading && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${columns}, 1fr)`,
                  gap: "15px",
                  width: "100%",
                  marginLeft: "40px",
                  padding: "10px",
                }}
              >
                {Array.from(new Array(16)).map((_, index) => (
                  <Grid
                    item
                    xs={6}
                    lg={3}
                    sx={{
                      minWidth: "100%",
                      backgroundColor: "rgb(0,0,0,0.07)",
                      padding: "16px",
                      height: "180px",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="d-flex gap-2 Card--Top my-1 p-1">
                      <Skeleton variant="circular" width={45} height={45} />

                      <div>
                        <Skeleton
                          variant="text"
                          width="8vw"
                          height={20}
                          sx={{ borderRadius: "5px" }}
                        />
                        <p>
                          <Skeleton
                            variant="text"
                            width="5vw"
                            height={20}
                            sx={{ borderRadius: "5px" }}
                          />
                        </p>
                      </div>
                    </div>
                    <div
                      className="Card--Timings d-flex justify-content-between w-100 p-2"
                      style={{ margin: "10px 0 10px 15px" }}
                    >
                      <Skeleton
                        variant="rounded"
                        width="45%"
                        height={20}
                        sx={{ borderRadius: "5px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width="45%"
                        height={20}
                        sx={{ borderRadius: "5px" }}
                      />
                    </div>

                    <div
                      className="d-flex gap-2 w-100"
                      style={{ margin: "15px 0 15px 10px" }}
                    >
                      <Skeleton
                        variant="rounded"
                        width="50%"
                        height={34}
                        sx={{ borderRadius: "20px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width="50%"
                        height={34}
                        sx={{ borderRadius: "20px" }}
                      />
                    </div>
                  </Grid>
                ))}
              </div>
            )}

            <div className="d-flex justify-content-end my-2">

              <Pagination
                count={Math.floor(getSessionManagement?.session_count / 16)}
                page={currentPage}
                onChange={(event, value) => setcurrPage(value)}

              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between gap-3">
            <div
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "22px",
                color: "#000000",
              }}
            >
              <span
                style={{ cursor: "pointer", color: "gray" }}
                onClick={() => setSessionView("default")}
              >
                Session Management
              </span>{" "}
              / Session Dashboard
            </div>

            {!isWebsocketDataRecieved ? (
              !session_status && (
                <div className="d-flex align-items-center gap-2 PrimaryBadge">
                  <img
                    alt=""
                    src="/assets/img/icons/sparkling.png"
                    width={12}
                    className="animate-pulse"
                  />
                  <span>Data processing</span>
                </div>
              )
            ) : (
              <Button
                className="PrimaryCTA p-1"
                onClick={() => setPingInterval(true)}
              >
                <CachedIcon style={{ fontSize: "25px" }} />
              </Button>
            )}
          </div>

          <div
            style={{
              backgroundColor: "white",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              borderRadius: "20px",
              marginTop: "25px",
              paddingTop: "15px",
            }}
          >
            <div
              className="d-flex align-items-center justify-content-between pb-3 mx-3"
              style={{
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              <div>Client Name: {expertDashboardData?.session_data?.joining_details?.clientName}</div>

              <div>
                Age: {expertDashboardData?.session_data?.demographics?.age}
              </div>

              <div>
                Sex: {expertDashboardData?.session_data?.demographics?.sex}
              </div>

              <div>
                Consultation Date: {new Date(expertDashboardData?.session_data?.joining_details?.expert_join_time).toLocaleDateString()}
              </div>
            </div>

            <div className="SessionDashboard--StatsCards mx-3">
              <SessionStatsCard
                isWebsocketDataRecieved={isWebsocketDataRecieved}
                title="Session Duration"
                titleHighlight={
                  expertDashboardData?.session_data?.joining_details
                    ?.total_session_duration === "" ||
                    expertDashboardData?.session_data?.joining_details
                      ?.total_session_duration == null ||
                    expertDashboardData?.session_data?.joining_details
                      ?.total_session_duration == undefined
                    ? "0 min"
                    : expertDashboardData?.session_data?.joining_details
                      ?.total_session_duration
                }
                expertTime={
                  expertDashboardData?.session_data?.joining_details
                    ?.expert_session_duration === "" ||
                    expertDashboardData?.session_data?.joining_details
                      ?.expert_session_duration == null ||
                    expertDashboardData?.session_data?.joining_details
                      ?.expert_session_duration == undefined
                    ? "0 min"
                    : expertDashboardData?.session_data?.joining_details
                      ?.expert_session_duration
                }
                clientTime={
                  expertDashboardData?.session_data?.joining_details
                    ?.user_session_duration === "" ||
                    expertDashboardData?.session_data?.joining_details
                      ?.user_session_duration == null ||
                    expertDashboardData?.session_data?.joining_details
                      ?.user_session_duration == undefined
                    ? "0 min"
                    : expertDashboardData?.session_data?.joining_details
                      ?.user_session_duration
                }
                expertTags={[]}
                clientTags={[]}
              />
              {/* Under Progress from backend */}
              <SessionStatsCard
                isWebsocketDataRecieved={isWebsocketDataRecieved}
                title="Sign In Time"
                // expertTime={formatTimestampTo12Hour(
                //   expertDashboardData?.session_data?.joining_details?.expert_join_time
                // )}
                expertTime={
                  expertDashboardData?.session_data?.joining_details
                    ?.expert_join_time
                    ? "To Join"
                    : formatTimestampTo12Hour(
                      expertDashboardData?.session_data?.joining_details
                        ?.expert_join_time
                    )
                }
                clientTime={formatTimestampTo12Hour(
                  expertDashboardData?.session_data?.joining_details
                    ?.user_join_time
                )}
                expertTags={[]}
                clientTags={[]}
              />

              {/* Under Progress from backend */}
              <SessionStatsCard
                isWebsocketDataRecieved={isWebsocketDataRecieved}
                title="Sign off Time"
                // expertTime={formatTimestampTo12Hour(
                //   expertDashboardData?.session_data?.joining_details
                //     ?.expert_signout_time
                // )}
                expertTime={
                  expertDashboardData?.session_data?.joining_details
                    ?.expert_signout_time === null
                    ? "To Join"
                    : formatTimestampTo12Hour(
                      expertDashboardData?.session_data?.joining_details
                        ?.expert_signout_time
                    )
                }
                clientTime={formatTimestampTo12Hour(
                  expertDashboardData?.session_data?.joining_details
                    ?.user_signout_time
                )}
                expertTags={[]}
                clientTags={[]}
              />

              {/* Under Progress from backend */}
              <SessionStatsCard
                isWebsocketDataRecieved={isWebsocketDataRecieved}
                title="On Camera"
                expertTime={
                  expertDashboardData?.session_data?.on_camera_details
                    ?.expert_camera_percent || "0%"
                }
                clientTime={
                  expertDashboardData?.session_data?.on_camera_details
                    ?.user_camera_percent || "0%"
                }
                expertTags={[
                  expertDashboardData?.session_data?.joining_details
                    ?.is_expert_on_time,
                ]}
                clientTags={[
                  expertDashboardData?.session_data?.joining_details
                    ?.is_user_on_time,
                ]}
              />

              {/* Under Progress from backend */}
              <SessionStatsCard
                isWebsocketDataRecieved={isWebsocketDataRecieved}
                title="Connectivity"
                expertTime={
                  expertDashboardData?.session_data?.connectivity_details
                    ?.expert
                    ? expertDashboardData?.session_data?.connectivity_details
                      ?.expert
                    : "0%"
                }
                clientTime={
                  expertDashboardData?.session_data?.connectivity_details?.user
                    ? expertDashboardData?.session_data?.connectivity_details
                      ?.user
                    : "0%"
                }
                expertTags={[]}
                clientTags={[]}
              />
            </div>

            <div className="SessionDashboard--GraphCards m-3">
              <SessionStatGraphCard
                isWebsocketDataRecieved={isWebsocketDataRecieved}
                title="Client’s Emotion"
                isLanguageCard={false}
                isEmotionCard={true}
                graphData={expertDashboardData?.session_data?.emotion_details} //{EmotionGraphData}
              />
              <SessionStatGraphCard
                isWebsocketDataRecieved={isWebsocketDataRecieved}
                title="Engagement"
                isLanguageCard={false}
                isEmotionCard={false}
                graphData={
                  expertDashboardData?.session_data?.engagement_details
                }
              />
              <SessionStatGraphCard
                isWebsocketDataRecieved={isWebsocketDataRecieved}
                title="Language Spoken"
                isLanguageCard={true}
                isEmotionCard={false}
                graphData={expertDashboardData?.session_data?.languages_spoken} //{LanguageSpokenData}
              />
            </div>

            <div className="SessionDashboard--TabsContainer">
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className="SessionDashboard--Tabs"
              >
                <Tab label="Session Note" />
                <Tab label="Prescription" />
                <Tab label="Follow-Up Summary" />
                <Tab label="Case History" />
                <Tab label="Session Transcript" />
              </Tabs>
            </div>
            <div className="SessionDashboard--TabItemsContainer">
              <CustomTabPanel value={selectedTab} index={0}>
                <div className="SessionTab--Panel SessionSessionNote--Panel">
                  <SessionNote
                    isWebsocketDataRecieved={isWebsocketDataRecieved}
                    sessionNoteData={
                      expertDashboardData?.session_data?.session_note || []
                    }
                  />
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={selectedTab} index={1}>
                <div className="SessionTab--Panel SessionPrescription--Panel">
                  <SessionPrescriptionTabItem
                    sessionStatus={session_status}
                    isWebsocketDataRecieved={isWebsocketDataRecieved}
                    prescriptionData={websocketData?.session_data?.prescription}
                    labtestData={websocketData?.session_data?.lab_test}
                  />
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={selectedTab} index={2}>
                <div className="SessionTab--Panel SessionSessionNote--Panel">
                  <FollowUpSummary
                    sessionStatus={session_status}
                    isWebsocketDataRecieved={isWebsocketDataRecieved}
                    follow_up_summary={
                      websocketData?.session_data?.follow_up_summary || {}
                    }
                  />
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={selectedTab} index={3}>
                <div className="SessionTab--Panel SessionSessionNote--Panel">
                  <CaseSummary
                    sessionStatus={session_status}
                    isWebsocketDataRecieved={isWebsocketDataRecieved}
                    case_summary={
                      websocketData?.session_data?.case_summary || {}
                    }
                  />
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={selectedTab} index={4}>
                <div className="SessionTab--Panel SessionSessionNote--Panel">
                  <SessionTranscript sessionStatus={session_status} />
                </div>
              </CustomTabPanel>
            </div>
          </div>

          <div
            className="p-2"
            style={{
              background: "#F9F5FF",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            {!expertDashboardData?.session_data?.submitted && (
              <Button
                onClick={() => {
                  handleSubmit();
                  setPingInterval(true);
                }}
                sx={{
                  marginTop: "20px",
                  width: "120px",
                  backgroundColor: "transparent",
                }}
                disabled={!isWebsocketDataRecieved}
                className="PrimaryCTA mx-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M14 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V4L14 0ZM9 16C7.34 16 6 14.66 6 13C6 11.34 7.34 10 9 10C10.66 10 12 11.34 12 13C12 14.66 10.66 16 9 16ZM12 6H2V2H12V6Z"
                    fill="white"
                  />
                </svg>
                Submit
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SessionManagement;
